import React, { useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import CopyrightText from "../components/CopyrightText";
import styled from "@emotion/styled";
import HeadingWithSub from "../components/HeadingWithSub";
import Seo from "../components/seo";
import ParentRouteHandler from "../components/ParentRouteHandler";
import CustomOverlayButton from "../components/fact-overlay/CustomOverlayButton";
import Accordion from "../components/Accordion";
import { colors } from "../utilities/themeVars";
import { replacedTermName } from "../utilities/helpers";
import redPlayButton from "../images/icon-play-button-red.svg";
import { StoreContext } from "../store";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const Container = styled.div`
  padding-bottom: 50px;

  .header-image {
    margin-bottom: 30px;
    min-height: 190px;
    position: relative;
  }

  .play-init-wrap {
    margin-bottom: 20px;
  }

  .details {
    margin-top: 30px;
  }

  .detail {
    margin-bottom: 4px;
  }

  .copyright-wrap {
    margin: 30px 0;
  }

  .accordion-text {
    margin: 20px 0;
  }

  .additional-info-wrap {
    border-top: solid 1px ${colors.gray3};
  }

  .artwork-info-wrap {
    &.kids {
      p {
        margin-bottom: 20px;
      }
    }
  }

  .tour-info-wrap {
    a {
      display: block;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      color: ${colors.primary};
      border-bottom: solid 1px ${colors.gray3};
      padding: 18px 0;
      outline: none;
      position: relative;
    }
    img {
      position: absolute;
      right: 10px;
      top: 12px;
      width: 26px;
    }
  }

  .back-to-list {
    position: fixed;
    bottom: ${(props) => (props.trackUrlNotNull ? "120px" : "20px")};

    left: 0;
    right: 0;
    padding: 0 10px;

    a {
      display: block;
      background: ${colors.primary};
      color: ${colors.white};
      padding: 10px;
      text-align: center;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const FactContainer = styled.div`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  left: 0;
  padding-bottom: 8px;
  position: absolute;
  right: 0;
  top: 0;
`;

const ArtworkTemplate = (props) => {
  const { location, data } = props;
  const { url: trackUrl } = useContext(StoreContext).audioDetails;

  const { nodeArtPages: artwork, allNodeTourStop } = data;
  const {
    field_fun_facts,
    field_include_families_questions,
    field_for_families_label_text,
    field_label_text,
    field_for_families_questions,
  } = artwork;

  const isKidsPage =
    field_include_families_questions && location.search.includes("kids=1");

  const artLabel =
    isKidsPage && field_for_families_label_text
      ? field_for_families_label_text
      : field_label_text[0]
      ? field_label_text[0]
      : null;

  const kidsQuestions =
    isKidsPage && field_for_families_questions
      ? field_for_families_questions
      : null;

  const [isOpen, setIsOpen] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const toggleAction = (index) => {
    setIsOpen(index);
  };

  const toggleLightBulb = () => {
    setIsClicked(true);
  };

  const parentRoute = isKidsPage ? "/look-at-art-together" : "/collection";
  const parentTitle = isKidsPage
    ? "Let's Look at Art Together"
    : "The Collection";

  const backToButtonLabel = isKidsPage
    ? "Back to Let's Look at Art Together"
    : "Back to The Collection";

  const tourStops = allNodeTourStop.edges
    .filter(({ node }) => {
      if (isKidsPage) {
        return node.relationships.field_related_tour[0].name
          .toLowerCase()
          .includes("kids");
      }
      return true;
    })
    .map(({ node: related_tour_stop }, index) => (
      <div key={index} className={"tour-info-wrap"}>
        <Link to={`${related_tour_stop.path.alias}?fromTour=1`}>
          {replacedTermName(
            related_tour_stop.relationships.field_related_tour[0].name
          )}{" "}
          Available
          <img src={redPlayButton} alt="Go to tour" />
        </Link>
      </div>
    ));

  return (
    <div>
      <Seo title={artwork.title} />
      <ParentRouteHandler title={parentTitle} path={parentRoute} />

      <Container trackUrlNotNull={trackUrl}>
        <div className={"content"}>
          <div className="header-image">
            {artwork.rel.image ? (
              <GatsbyImage
                image={getImage(artwork.rel.image?.rel.media.localFile)}
                alt="Artwork Image"
              />
            ) : (
              <StaticImage src="../images/artwork-placeholder.jpg" height={500} width={500} alt="placeholder" />
            )}

            {!!field_fun_facts.length && (
              <FactContainer>
                <Wrapper>
                  {field_fun_facts.map((fact, index) => (
                    <CustomOverlayButton
                      key={index}
                      numb={index}
                      data={fact}
                      setActiveFact={setIsOpen}
                      activeFactIndex={isOpen}
                      toggle={toggleAction}
                      isClicked={isClicked}
                      setIsClicked={setIsClicked}
                      lightBulbClick={toggleLightBulb}
                    />
                  ))}
                </Wrapper>
              </FactContainer>
            )}
          </div>

          <HeadingWithSub
            main={artwork.title}
            subTitle={artwork.rel.artist[0].artist_name}
          />
          <div className="details">
            <div className={"detail"}>{artwork.field_label_date}</div>
            <div className={"detail"}>{artwork.field_media_and_support}</div>
            <div className={"detail"}>{artwork.field_label_dimensions}</div>
            <div className={"detail"}>{artwork.field_artwork_image_credit}</div>

            <div className="copyright-wrap">
              <CopyrightText
                field_artwork_copyright={artwork.field_artwork_copyright}
                artist_copyright={artwork.rel.artist[0].copyright}
                artist_name={artwork.rel.artist[0].artist_name}
              />
            </div>

            <div className="additional-info-wrap">
              {artLabel && (
                <div className={"artwork-info-wrap"}>
                  <Accordion title={"About This Artwork"} defaultOpen={true}>
                    <div
                      className={"accordion-text"}
                      dangerouslySetInnerHTML={{
                        __html: artLabel.processed,
                      }}
                    />
                  </Accordion>
                </div>
              )}

              {kidsQuestions && (
                <div className={"artwork-info-wrap kids"}>
                  <Accordion title={"Questions for kids"} defaultOpen={true}>
                    <div
                      className={"accordion-text"}
                      dangerouslySetInnerHTML={{
                        __html: kidsQuestions.processed,
                      }}
                    />
                  </Accordion>
                </div>
              )}

              {tourStops}

              {artwork.rel.artist[0].about_artist && (
                <div className={"artist-info-wrap"}>
                  <Accordion title={"More About This Artist"}>
                    <div
                      className={"accordion-text"}
                      dangerouslySetInnerHTML={{
                        __html: artwork.rel.artist[0].about_artist.processed,
                      }}
                    />
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={"back-to-list"}>
          <Link to={parentRoute}>{backToButtonLabel}</Link>
        </div>
      </Container>
    </div>
  );
};

export default ArtworkTemplate;

export const query = graphql`
  query artwork($drupal_id: String!) {
    allNodeTourStop(
      filter: {
        relationships: {
          field_related_artwork: {
            elemMatch: { drupal_id: { eq: $drupal_id } }
          }
        }
      }
    ) {
      edges {
        node {
          title
          drupal_id
          path {
            alias
          }
          relationships {
            field_related_tour {
              name
            }
          }
        }
      }
    }
    nodeArtPages(drupal_id: { eq: $drupal_id }) {
      title
      field_artwork_copyright
      field_artwork_image_credit
      field_label_date
      field_media_and_support
      field_label_dimensions
      field_fun_facts {
        processed
      }
      field_label_text {
        processed
      }
      field_include_families_explore
      field_include_families_questions
      field_for_families_questions {
        processed
      }
      field_for_families_label_text {
        processed
      }
      rel: relationships {
        artist: field_artist_ {
          artist_name: title
          copyright: field_default_artwork_copyright {
            processed
          }
          about_artist: body {
            processed
          }
        }
        image: field_preview_sharing_image_med {
          rel: relationships {
            media: field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
