import React from "react";
import styled from "@emotion/styled";

const CustomOverlay = ({ data, numb, show, clicked }) => {
  const closeOverlay = () => {
    show(null);
    clicked(false);
  };

  const FactOverlay = styled.div`
    background-color: rgba(51, 63, 72, 0.8);
    bottom: 0;
    padding: 16px;
    position: absolute;
    top: 0;

    h2,
    p {
      color: #fff;
    }
  `;

  const CloseBtn = styled.button`
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    position: absolute;
    right: 16px;
    top: 16px;
  `;

  return (
    <FactOverlay>
      <h2>Fun Fact</h2>
      <p
        data-numb={numb}
        dangerouslySetInnerHTML={{
          __html: data.processed,
        }}
      />
      <CloseBtn onClick={() => closeOverlay()}>CLOSE</CloseBtn>
    </FactOverlay>
  );
};

export default CustomOverlay;
