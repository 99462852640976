import React from "react";
import redLightBulbImg from "../../images/icon-light-red.svg";
import greyLightBulbImg from "../../images/icon-light-grey.svg";
import styled from "@emotion/styled";
import CustomOverlay from "./CustomOverlay";

const CustomOverlayButton = ({
  data,
  numb,
  setActiveFact,
  activeFactIndex,
  toggle,
  isClicked,
  setIsClicked,
  lightBulbClick,
}) => {
  const Btn = styled.div`
    margin-left: 8px;
    z-index: 1;
  `;

  return (
    <>
      {!isClicked && (
        <Btn
          onClick={() => {
            lightBulbClick();
            toggle(numb);
          }}
        >
          <img src={redLightBulbImg} alt="light bulb" data-fact={numb} />
        </Btn>
      )}

      {isClicked && (
        <Btn onClick={() => toggle(numb)}>
          {activeFactIndex === numb ? (
            <img src={redLightBulbImg} alt="light bulb" data-fact={numb} />
          ) : (
            <img src={greyLightBulbImg} alt="light bulb" data-fact={numb} />
          )}
        </Btn>
      )}

      {activeFactIndex === numb ? (
        <CustomOverlay
          numb={numb}
          data={data}
          show={setActiveFact}
          clicked={setIsClicked}
        />
      ) : null}
    </>
  );
};

export default CustomOverlayButton;
